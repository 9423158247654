.css-1-featureCardColumn
{
box-sizing: border-box;
margin: 0;
min-width: 0;
display: -webkit-box;
display: -webkit-flex;
/* display: flex; */
align-items: center;
flex-direction: column;
margin-bottom: -5px;
text-align: center;
padding-left: 20px;
padding-right: 20px;
}

.css-2-featureCardColumn {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    /* margin-bottom: -10px; */
    margin-bottom: 20px;
    padding-bottom: 10px;
    width: 60px;

}

.css-3-featureCardColumn {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}

.css-4-featureCardColumn {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-weight: 500;
    line-height: 1.5;
    font-size: 20px;
    font-family: 'DM Sans', sans-serif;
    letter-spacing: -.55px;
    color: #0f2137;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 700;
    margin-bottom: 10px;

}

.css-5-featureCardColumn {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.9;
    color: #343D48;
}

@media screen and (min-width: 480px) {
    .css-1-featureCardColumn {
        /* align-items: flex-start; */
    }
}

@media screen and (min-width: 640px) {
    .css-1-featureCardColumn {
        text-align: center;
        padding-left: 0;
        padding-right: 0;
    }

    .css-2-featureCardColumn {
        margin-left: -13px;
        margin-right: 0;
    }

    .css-4-featureCardColumn {
        margin-bottom: 15px;
    }
}

@media screen and (min-width: 768px) {
    .css-2-featureCardColumn {
        width: 90px;
    }
}

@media screen and (min-width: 1024px) {
    .css-2-featureCardColumn {
        width: 80px;
        padding-bottom: 20px;
    }  
}

@media screen and (min-width: 1220px) {
    .css-2-featureCardColumn {
        width: 100px;
        padding-bottom: 20px;
    }
}




