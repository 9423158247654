* {
  box-sizing: border-box;
}

/* .App {

  text-align: center;
  position: relative;
  min-height: 100vh;
  background: #FFFFFF;
  border-color: #E5ECF4;
  font-family: 'DM Sans', sans-serif;
  font-size: 12;
 
} */

.App {
  font-family: 'DM Sans',sans-serif;
  
}

body {
  --watermark-color: #223C7D;
  --watermark-background: #e6ecf0;
  --watermark-font-color: #343d48;
  --watermark-body-font: 'DM Sans',sans-serif;
    line-height: 1.8;
    font-weight: normal;

}


.sectionHeader {
box-sizing: border-box;
margin: 0;
min-width: 0;
display: -webkit-box;
display: -webkit-flex;
font-family: 'Copperplate';
margin-left: auto;
margin-left: right;
text-align: center !important;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
margin-top: -3px;
margin-bottom: 50px !important;
margin: auto;
}

@media screen and (min-width: 414px) {
.sectionHeader {
  margin-bottom: 30px !important;
}
}

@media screen and (min-width: 428px) {
  .sectionHeader {
    margin-bottom: 20px !important;
  }
  }

@media screen and (min-width: 628px) {
  .sectionHeader {
    margin-bottom: 20px !important;
  } 
}

@media screen and (min-width: 834px) {
  .sectionHeader {
    margin-bottom: 30px !important;
  } 
}

@media screen and (min-width: 1024px) {
 
}

@media screen and (min-width: 1440px) {
   

}

@media screen and (min-width: 1920px) {
   

}


.sectionHeader > .title {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-size: 24px;
  color: #02073E;
  line-height: 1.3;
  text-align: center;
  font-weight: 700;
  letter-spacing: -.5px;
}

.subTitle {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Copperplate';
  min-width: 0;
  font-size: 12px;
  color: var(--watermark-color);
  text-align: center !important;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1.5;
  opacity: 1;
}

.content-wrap {
  /* padding-bottom: 100px; */
}

section {
display: block;
/* padding-top: 300px; */
/* padding-bottom: 100px; */
/* background-color: #1075b6; */
color: #939598;

}

/* .primary-button {
  flex-shrink: 0;
  margin-right: 15px;
  border-radius: 45px;
  font-size: 14px;
  cursor: pointer;
  line-height: 1.2;
  padding: 12px 20px;
  letter-spacing: -0.15px;
  font-weight: 500;
} */


.heroSecondary {
/* padding-left: 30px;
padding-right: 30px; */
line-height: 2;
text-align: center;
color: black;
margin-bottom: 30px !important;
}

@media screen and (min-width: 1220px) {
.title {
  font-size: 32px;
  } 
}

@media screen and (min-width: 768px) {
  .heroSecondary {
    line-height: 2;
    padding-left: 30px;
    padding-right: 30px;
  }

  .subTitle {
    font-size: 14px !important;
  }

  .title {
    line-height: 1.25;
  }
}



@media screen and (min-width: 640px) {
  .heroSecondary {
    font-size: 17px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .subTitle {
    letter-spacing: 2px;
  }

  .title {
    font-size: 28px;
  }
}

@media screen and (min-width: 480px) {
  .subTitle {
    font-size: 13px;
  }
}

.primary-button {
  box-sizing: border-box;
  margin: 0;
  border: none !important;
  appearance: none;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  margin-bottom: 50px;
  color: white;
  background-color: #223C7D;
  border-radius: 45px;
  font-size: 14px;
  padding: 12px 20px;
  font-family: 'DM Sans', sans-serif;
  cursor: pointer;
  line-height: 1.2;
  transition: all 0.25s;
  font-weight: 500;
}

.primary-button a {
  text-decoration: none;
  color: white;
}