.handler {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 26px;
}

  .drawer {
      width: 100%;
      height: 100%;
      /* background-color: darkblue; */
  }

  .close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 25px;
      right: 30px;
      z-index: 1;
      cursor: pointer;
  }

  .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 100px;
      padding-bottom: 40px;
      padding-left: 30px;
      padding-right: 30px;
  }

  .menu {
      width: 100%;
      display: flex;
      flex-direction: column;
  }

  .menu > a {
      font-size: 16px;
      font-weight: 500;
      color: white;
      padding-left: 15px;
      padding-right: 15px;
      cursor: pointer;
      border-bottom: 1px solid #e8e5e5;
      transition: all 0.25s;
  }

  .menuFooter {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: auto;
  }

  .social {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .social > .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #343D48;
      margin-right: 15px;
      transition: all 0.25s;
      cursor: pointer;
  }

  .button {
      color: white; 
      font-size: 14px;
      font-weight: 700;
      height: 45px;
      border-radius: 5px;
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0;
      padding-right: 0;
  }

  @media screen and (min-width: 1024px) {
    .handler {
        display: none;
    } 
  }