.css-1-footer {
    
}

.css-2-footer-topArea {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    max-width: container;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.css-3-footer-bottomArea {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    border-top: 1px solid;
    border-top-color: var(--theme-ui-colors-border_color,#E5ECF4);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 50px;
    padding-bottom: 40px;
    text-align: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.css-4-footer-menus {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    margin-top: 15px;
    margin-bottom: 10px;
}

.css-4-footer-menus > nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.css-5-footer-links {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 15px;
    color: var(--theme-ui-colors-text,#343D48);
    font-weight: 400;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
    display: block;
    -webkit-text-decoration: none;
    text-decoration: none;
    line-height: 1.5;
    padding-left: 10px;
    padding-right: 10px;
}

.css-6-footer-copyright {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 15px;
    width: 100%;
}

@media screen and (min-width: 480px) {
    .css-2-footer-topArea {
        padding-left: 30px;
        padding-right: 30px;
    }

    .css-4-footer-menus {
        margin-top: 20px;
    }

    .css-5-footer-links {
        font-size: 15px;
    }

    .css-6-footer-copyright {
        font-size: 15px;
    }
}

@media screen and (min-width: 640px) {
    .css-3-footer-bottomArea {
        padding-top: 80px;
        padding-bottom: 100px;
    }

    .css-5-footer-links {
        line-height: 1.8;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (min-width: 768px) {
    .css-2-footer-topArea {
        max-width: 780px;
    }
}

@media screen and (min-width: 1024px) {
    .css-2-footer-topArea {
        max-width: 1020px;
    }
}

@media screen and (min-width: 1220px) {
    .css-2-footer-topArea {
        max-width: 1200px;
    }
}

@media screen and (min-width: 1366px) {
  
}

@media screen and (min-width: 1620px) {
   
}