.css-1-about {
    box-sizing: border-box;
margin: 0;
min-width: 0;
width: 100%;
margin-left: auto;
margin-right: auto;
max-width: 100%;
padding-left: 20px;
padding-right: 20px;
min-height: inherit;
display: -webkit-box;
display: -webkit-flex;
display: flex;
justify-content: center;
flex-direction: column;
}

@media screen and (min-width: 480px) {
    #about {
        padding-top: 20px;
    }    

    .css-1-about {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (min-width: 640px) {
    
}

@media screen and (min-width: 768px) {
    
    
}

@media screen and (min-width: 1024px) {
    #about {
        padding-top: 50px;
    }    
}

@media screen and (min-width: 1220px) {
    #about {
        padding-top: 50px;
    }    

}

