.newsletter {
    /* padding-top: 50px; */
    
    
    display: grid;
    max-width: fit-content;
    text-align: center;
}

.newsletter > form {
    display: inline-grid;
}

.newsletter__text {
    color: black;
    font-size: 10px;
}

.form__control {
    padding: 10px;
    border: none;
    background-color: #DCDCDC;
    /* max-width: 300px; */
    border-radius: 100px;
    margin-bottom: 10px;
}

.newsletter__button {
    background-color: #1075b6;
    color: white;
    max-width: fit-content;
    border: none;
    padding: 10px;
    margin-top: 10px;
}

