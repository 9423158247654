.css-1-BioBanner {
    /* padding-top: 140px; */
    
    /* padding-bottom: 10px; */
    width: 100%;
    height: 100%; 
    background-size: contain;
    
    background-color: rgba(228, 228, 228,  1);
    background-image: url("../assets/collin_cooper_transparent.png");
    background-repeat: no-repeat;
    
    /* background-position: center; */
 }

 .css-1-bio_banner {
     width: 100%;
     position: relative;
     flex-wrap: wrap;
     display: flex;
     max-width: 90rem;
     margin: auto;
 }

 .css-4-breadcrumb {

 }

 .bio_name_text {
     font-size: 3.125rem;
 }

 .bio_name_text {
    color: #223C7D;
}

 .css-2-bio_banner_info {
     width: 100%;
     position: relative; 
     flex-wrap: wrap;
     display: flex;
 }

 .css-5-contact-info {
     bottom: -1rem;
     width: 50%;
     color: rgba(255, 255, 255, 1);
     padding-top: 1.5rem;
     padding-bottom: 1.5rem;
     justify-content: space-between;
     align-items: center;
     flex-wrap: wrap;
     display: flex;
     background-color: #223C7D;
    
 }


 .css-3-bio-info {
     padding-left: 5rem;
     padding-right: 5rem;
     /* width: 50%; */
     padding-top: 2.5rem;
     background-color: rgba(228, 228, 228,  1);
 }

 .css-3-bio_banner_image {
    width: 50%;
 }

 .css-4-bio_banner_image {
     background-image: url("../assets/collin_cooper_transparent.png");
     width: 100%;
     height: 100%;
     background-size: contain;
     background-repeat: no-repeat;
     background-color:rgba(228, 228, 228,  1);
 }

 .BioBanner_Info {
     background-color: white;
     z-index: 2;
     box-sizing: border-box;
 }
 
 .css-2-Banner {
 box-sizing: border-box;
 margin: 0;
 min-width: 0;
 width: 100%;
 margin-left: auto;
 margin-right: auto;
 max-width: 100%;
 padding-left: 20px;
 
 padding-right: 20px;
 min-height: inherit;
 display: -webkit-box;
 display: -webkit-flex;
 display: flex;
 justify-content: center;
 flex-direction: column;
 
 }
 
 .css-3-Banner {
     box-sizing: border-box;
     margin: 0;
     min-width: 0;
     
     text-align: center !important;
     width: 100%;
     margin-left: auto;
     margin-right: auto;
     margin-bottom: 40px;
 }
 
 .css-4-BioBanner {
     /* box-sizing: border-box;
     
     margin: 0;
     min-width: 0;
     justify-content: center;
     text-align: center;
     display: inline-flex;
     margin-bottom: 0;
     display: -webkit-inline-box;
     display: -webkit-inline-flex;
     display: -ms-inline-flexbox; */
     background-color: #213F7D;
     color: white;
     font-weight: 600;
     width: 100%;
 }
 
 .css-5-Banner {
     box-sizing: border-box;
     margin: 0;
     min-width: 0;
     max-width: 100%;
     height: auto;
 }
 
 .banner-tagline {
     /* max-width: 250px;    */
     max-width: 350px;   
     margin-bottom: 100px;
     margin-left: auto;
     display: flex;
     margin-right: auto;
 }
 
 
 .imageBox {
     justify-content: center;
     text-align: center;
     display: inline-flex;
     margin-bottom: 40px;
 }
 
 .imageBox > img {
     position: relative;
     height: 245;
 }
 
 @media screen and (min-width: 480px) {
     .css-1-BioBanner {
         padding-top: 145px;
     }
     .css-2-Banner {
         padding-left: 30px;
         padding-right: 30px;
     }
     .css-3-Banner {
         width: 90%;
     }
 }
 
 @media screen and (min-width: 640px) {
     .css-1-BioBanner {
         padding-top: 155px;
         padding-bottom: 0;
     }
     
     .css-3-Banner {
         width: 535px;
     }
 
     .css-4-BioBanner {
         margin-bottom: -30px;
     }
 }
 
 @media screen and (min-width: 768px) {
     .css-1-BioBanner {
         padding-top: 170px;
     }
     .css-2-Banner {
         max-width: 780px;
     }
     
 }
 
 @media screen and (min-width: 1024px) {
     .css-1-BioBanner {
         padding-bottom: 10px;
     }
 
     .css-2-Banner {
     max-width: 1020px;
     }
     .css-3-Banner {
         width: 57%;
     }
 }
 
 @media screen and (min-width: 1220px) {
     .css-4-BioBanner {
     margin-bottom: -40px;
     }
 
 }
 
 