.css-1-keyFeature {
    padding-top: 30px;
    /* padding-bottom: 100px; */
}
.css-2-keyFeature {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.css-3-keyFeature-grid {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: grid;
    grid-gap: 15px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    grid-gap: 35px 0;
    grid-template-columns: repeat(1,1fr);
}

@media screen and (min-width: 480px) {
    .css-2-keyFeature {
        padding-left: 30px;
        padding-right: 30px;
    }

    .css-3-keyFeature-grid {
        width: 80%;
    }
}

@media screen and (min-width: 640px) {

    .css-1-keyFeature {
        padding-top: 50px;
        /* padding-bottom: 100px; */
    }

    .css-3-keyFeature-grid {

        width: 100%;
        grid-gap: 40px -40px;
        grid-template-columns: repeat(2, 1fr);
    }

}

@media screen and (min-width: 768px) {
    .css-2-keyFeature {
        max-width: 780px;
    }

    .css-3-keyFeature-grid {

        grid-gap: 50px -60px;
    }
}

@media screen and (min-width: 1024px) {
    .css-2-keyFeature {
        max-width: 1020px;
    }

    .css-3-keyFeature-grid {
        grid-gap: 30px;
        grid-template-columns: repeat(4,1fr);

    }

}

@media screen and (min-width: 1220px) {
    .css-2-keyFeature {
        max-width: 1200px;
    }

    .css-1-keyFeature {
        padding-top: 120px;
        /* padding-bottom: 120px; */
    }

    .css-3-keyFeature-grid {
        grid-gap: 50px -40px;
    }
}

@media screen and (min-width: 1366px) {
    .css-3-keyFeature-grid {
        grid-gap: 55px 90px;
    }
    }

    @media screen and (min-width: 1366px) {
    .css-1-keyFeature {
        padding-top: 40px;
        /* padding-bottom: 100px; */
    }
}