.css-1-Banner {
   top: 50px;
   padding-top: 250px;
   padding-bottom: 50px;
   margin-bottom: 10px;
   position: relative;
   background-image: url("../assets/skyline.jpg");
   background-position: 0vmin 1rem;
   background-repeat: no-repeat;
   background-size: 100%;
}

.css-2-Banner {
box-sizing: border-box;
margin: 0;
min-width: 0;
width: 100%;
margin-left: auto;
margin-right: auto;
max-width: 100%;
padding-left: 20px;
padding-right: 20px;
min-height: inherit;
display: -webkit-box;
display: -webkit-flex;
display: flex;
justify-content: center;
flex-direction: column;
}

.css-3-Banner {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    justify-content: center;
    text-align: center !important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    /* margin-bottom: 40px; */
}

.css-4-Banner {
    /* box-sizing: border-box;
    
    margin: 0;
    min-width: 0;
    justify-content: center;
    text-align: center;
    display: inline-flex;
    margin-bottom: 0;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox; */
    
}

/* .css-5-Banner {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    max-width: 100%;
    height: auto;
} */

.banner-tagline {
    /* max-width: 250px;    */
    width: 300px;   
    top: 50px;    
    
    left: 30px;
    /* translate: (-50%, -50%); */
    /* margin-bottom: 100px; */
    position: absolute;
    margin-left: auto !important;
    /* display: flex; */
    margin-right: auto !important;

}



.banner-button {
    /* box-sizing: border-box; */
    /* margin: 0; */
    border: none !important;
    top: 170px;
    left: 125px;
    position: absolute;
    
    appearance: none;
    /* display: inline-block; */
    text-align: center;
    text-decoration: none;
    margin-bottom: 50px;
    color: white;
    background-color: #223C7D;
    border-radius: 45px;
    font-size: 14px;
    padding: 12px 20px;
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;
    line-height: 1.2;
    transition: all 0.25s;
    font-weight: 500;
}

.banner-button a {
    text-decoration: none;
    color: white;
  }

.imageBox {
    justify-content: center;
    text-align: center;
    display: inline-flex;
    margin-bottom: 40px;
}

.imageBox > img {
    position: relative;
    height: 245;
}

@media screen and (min-width: 414px) {
    .css-1-Banner {
        padding-top: 280px;
        padding-bottom: 50px;
    }

    .banner-tagline {
        left: 60px;
    }

    .banner-button {
        top: 200px;
        left: 150px;
    }
}

@media screen and (min-width: 428px) {
    .css-1-Banner {
        padding-top: 280px;
        padding-bottom: 50px;
    }
    .banner-tagline {
        left: 65px;
        top: 45px;
    }

    .banner-button {
        top: 195px;
        left: 155px;
        ;
    }
}

@media screen and (min-width: 480px) {
    .css-1-Banner {
        padding-top: 250px;
        padding-bottom: 50px;
    }
    .css-2-Banner {
        padding-left: 30px;
        padding-right: 30px;
    }
    .css-3-Banner {
        width: 90%;
    }
}

@media screen and (min-width: 640px) {
    .css-1-Banner {
        padding-top: 200px;
        padding-bottom: 50px;
    }
    
    .css-3-Banner {
        width: 535px;
    }

    .css-4-Banner {
        margin-bottom: -30px;
    }
    
}

@media screen and (min-width: 768px) {
    .css-1-Banner {
        padding-top: 170px;
    }
    .css-2-Banner {
        max-width: 780px;
    }
    .banner-tagline {
        width: 500px !important;   
        top: 100px;
        }
    
}

@media screen and (min-width: 833px) {
    .css-1-Banner {
        padding-top: 500px;
        padding-bottom: 50px;
    }

    .banner-tagline {
        max-width: 600px !important;
        top: 75px;
        left: 165px;
    }

    .banner-button {
        top: 430px;
        left: 350px;  
        padding: 18px 26px;    
        font-size: 16px;  

    }
}

@media screen and (min-width: 1024px) {
    .css-1-Banner {
        padding-top: 600px;
        padding-bottom: 60px;
    }

    .css-2-Banner {
    max-width: 1020px;
    }
    .css-3-Banner {
        width: 57%;
    }
    .banner-tagline {
        width: 500px !important;   
        left: 265px;
        top: 100px;
        }

    .banner-button {
        top: 550px;
        left: 440px;     
        padding: 20px 28px;    
        font-size: 18px;     
    }
}

@media screen and (min-width: 1220px) {
    .css-1-Banner {
        padding-top: 650px;
        padding-bottom: 50px;
    }
    .css-4-Banner {
    margin-bottom: -40px;
    }
    .banner-tagline {
    max-width: 750px !important;   
    
    }
}

@media screen and (min-width: 1440px) {
    .css-1-Banner {
        padding-top: 550px;
        padding-bottom: 100px;
        margin-bottom: 40px;
    }

    .banner-tagline {
        width: 600px !important;   
        left: 420px;
        top: 100px;
        }

        .banner-button {
            top: 550px;
            left: 650px;        
        }
}

@media screen and (min-width: 1680px) {
    .css-1-Banner {
        padding-top: 650px;
        padding-bottom: 100px;
        margin-bottom: 50px;
    }

    .banner-tagline {
        width: 750px !important;   
        left: 465px;
        top: 150px;
        }

    .banner-button {
        top: 630px;
        left: 750px;        
        padding: 24px 32px;    
        font-size: 22px;     
    }
}




@media screen and (min-width: 1920px) {
    .css-1-Banner {
        padding-top: 750px;
        padding-bottom: 100px;
        margin-bottom: 50px;
    }

    .banner-tagline {
        width: 800px !important;   
        left: 575px;
        top: 150px;
        }

    .banner-button {
        top: 750px;
        left: 865px;        
        padding: 24px 32px;    
        font-size: 22px;     
    }
}

@media screen and (min-width: 2559px) {
    
    .css-1-Banner {
        padding-top: 1050px;
        padding-bottom: 100px;
        margin-bottom: 50px;
        
     }

    .banner-tagline {
        width: 12s00px !important;   
        left: 910px;
        top: 150px;
        }

    .banner-button {
        top: 950px;
        left: 1180px;        
        padding: 30px 38px;    
        font-size: 25px;     
    }
}

