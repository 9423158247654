.header {
  color: #343D48;
  font-weight: normal;
  width: 100%;
  position: absolute;
  top: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  left: 0;
  /* background-color: transparent; */
  background-color: white;
  transition: all 0.4s ease;
  /* animation: ; */
}

.header.sticky {
  background-color: white;
  box-shadow: 0 1px 2px rgb(0 0 0 / 6%);
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-nav {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.donate__btn {
  flex-shrink: 0;
  margin-right: 15;
  margin-left: auto;
}

.header-logo {
  display: flex;
  cursor: pointer;
  max-width: 100px;
  margin-right: 15px;
}
/* .header {
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-bottom: 1px solid lightgrey;
    position: relative;
    position: sticky;
    top: 0;
    z-index: 1;
    align-items: center;
    max-width: 100%;
  } */

  .header__button {
    visibility: hidden;
    flex-shrink: 0;
    margin-right: 15px;
    border-radius: 45px;
    font-size: 16px;
    cursor: pointer;
    line-height: 1.2;
    padding: 12px 20px;
    letter-spacing: -0.15px;
    font-weight: 500;
    color: var(--watermark-color);
    border: 2px solid;
    border-color: var(--watermark-color);
    background-color: transparent;
  }

  .header__button:hover {
    background-color: var(--watermark-color);
    color: white;
  }

.nav {
  margin: auto;
  display: none;
  box-sizing: border-box;
  min-width: 0;
  margin: 0;  
}

.nav > a {
font-weight: 16px;
font-weight: normal;
padding-left: 25px;
padding-right: 25px;
cursor: pointer;
line-height: 1.2;
transition: all 0.15s;
}

.nav > a.active {
  color: var(--watermark-color);
}

.a:-webkit-any-link {
  text-decoration: none;
}
  .header__logo {
    
    display: block;
    /* padding: 5px; */
    max-width: 100px;
    object-fit: contain;
    
  }

  .header__menu__button {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border: none;
    border-radius: 100px;
    text-align: right;
    float: right;
  }

  .header__menu__button__desktop {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border: none;
    border-radius: 100px;
    text-align: right;
    float: right;
    margin-right: 10px;
  }

  .header__menu__button__desktop > a {
    color: white;

    text-decoration: none;  
    
  }

  .header__menu__button > a {
    color: white;
    text-decoration: none;  
    
  }

  .header__menu__text {
    text-decoration: none !important;
    color: white;
    /* color: #8E8E8E; */
  }

  .header__logo__image {
    max-width: 100px;
  }

  .header__icon__hidden {
  visibility: hidden;
  }

  .header > .MuiIconButton-root {
    padding: 10px;    
}

@media screen and (min-width: 480px) {
  .header-nav {
    padding-left: 30px;
    padding-right: 30px;
  }

  .header__button {
    margin-right: 20px;
  }
}

@media screen and (min-width: 640px) {
  
}

@media screen and (min-width: 768px) {
  
  .header-nav
  {
    max-width: 780px;
  }
  
}

@media screen and (min-width: 800px) {
  
  .header-nav
  {
    max-width: 850px;
  }
  
}

@media screen and (min-width: 1024px) {
   .header__button {
     margin-right: 0;
     margin-left: 0;
   }

   .header__menu {
    display: none;
  }

   .nav {
    display: block;
    margin: auto;
}

  .header-nav {
    max-width: 1124px;
  }

}

@media screen and (min-width: 1220px) {
  .header-nav 
  {
      max-width: 1200px;
  } 
}

@media screen and (min-width: 1366px) {
  .header__logo {
    max-width: 120px;
  }

  .header-nav {
    max-width: 1460px;
  }
}

@media screen and (min-width: 1620px) {
  .header__button {
    
  }   
  .header-nav 
  {
      max-width: 1760px;
  }

  .header__logo {
    max-width: 150px;
  }
}

@media screen and (min-width: 1920px) {
  
  .header-nav 
  {
      max-width: 1960px;
  }

  
} 

@media screen and (min-width: 2560px) {
  
  .header-nav 
  {
      max-width: 2650px;
  }

  
} 